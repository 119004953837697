import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { navigate } from 'gatsby';
import Helmet from 'react-helmet';

const ContactPage = () => {
  const [inputs, setInputs] = React.useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    message: '',
  });

  let formAPI = '';
  if (process.env.NODE_ENV == 'development') {
    // Local Dev.
    formAPI = 'https://api.formik.com/submit/bluevalue/bluevalue-contact-test-form';
  } else {
    formAPI = 'https://api.formik.com/submit/bluevalue/contact';
  }

  const handleSubmit = async () => {
    await fetch(formAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(inputs),
    })
      .then(response => {
        response.json();
      })
      .then(_data => {
        navigate('/confirmation/');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const verifyCallback = e => {
    e.preventDefault();
    // Execute() is a recaptha function explicitly called on submit.
    recaptchaRef.current.execute();
  };

  const recaptchaRef = React.createRef();

  return (
    <>
      <Helmet title="Contact" />
      <section className="contact-page">
        <div className="inner inner-padding">
          <h2 className="text-center">Contact Us</h2>
          <p className="text-center contact--intro mb-10">
          Send us your questions or comments, or if we missed a study that should be included in the database, fill out the form below or send us an email and we will get back to you as soon as possible.
          </p>
        </div>
      </section>
      <section className="relative section--contact mb-10">
        <div className="inner inner-padding">
          <div className="contact--transparent mb-20">
            <h3 className="text-center lightwhiteblue mb-2 px-6 lg:px-10 lg:text-left">Contact Information</h3>
            <div className="px-6 lg:px-10 pb-8 contact-info flex lg:w-full">
              <div className="child pr-0 lg:pr-10 flex-grow">
                <div className="location flex">
                  <span className="icon w-5 h-5 mr-4"></span>
                  <p className="text-base">
                  Socio-Economics Group<br />
                  Harte Research Institute for Gulf of Mexico Studies<br />
                  Texas A&amp;M University–Corpus Christi<br />
                  6300 Ocean Drive, Unit 5869<br />
                  Corpus Christi, Texas 78412<br />
                  </p>
                </div>
                <div className="mail flex items-center">
                  <span className="icon w-5 h-5 mr-4"></span>
                  <a
                    href="mailto:seg-hri@tamucc.edu"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    seg-hri@tamucc.edu
                  </a>
                </div>
              </div>
            </div>
            <div className="formwrapper pt-10  mb-2">
              <form className="px-6 lg:px-10 flex-wrap flex" method="POST" onSubmit={ e => { e.preventDefault();} }>
                <div className="mt-12 form_item relative flex  w-full lg:w-1/2">
                  <label className="absolute top-1" htmlFor="firstname">First name</label>
                  <input
                    name="firstname"
                    type="text"
                    placeholder="First name"
                    value={inputs.firstname}
                    onChange={handleInputChange}
                    className="w-full shadow appearance-none border rounded py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white mr-4"
                    required
                  />
                </div>
                <div className="mt-12 form_item relative flex  w-full lg:w-1/2">
                  <label className="absolute top-1 ml-4" htmlFor="lastname">Last name</label>
                  <input
                    name="lastname"
                    type="text"
                    placeholder="Last name"
                    value={inputs.lastname}
                    onChange={handleInputChange}
                    className="w-full shadow appearance-none border rounded  py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white ml-4"
                    required
                  />
                </div>
                <div className="mt-12 form_item relative flex  w-full lg:w-1/2">
                  <label className="absolute top-1" htmlFor="phone">Phone number</label>
                  <input
                    name="phone"
                    type="text"
                    placeholder="Phone number"
                    value={inputs.phone}
                    onChange={handleInputChange}
                    className="w-full shadow appearance-none border rounded py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white mr-4"
                  />
                </div>
                <div className="mt-12 form_item relative flex  w-full lg:w-1/2">
                  <label className="absolute top-1 ml-4" htmlFor="email">Email address</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="Email address"
                    value={inputs.email}
                    onChange={handleInputChange}
                    className="w-full shadow appearance-none border rounded w-1/2 py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white ml-4"
                    required
                  />
                </div>
                <div className="mt-12 form_item relative flex  w-full">
                  <label className="absolute top-1" htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    rows="10"
                    value={inputs.message}
                    onChange={handleInputChange}
                    className="shadow appearance-none border rounded w-1/2 py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
                    placeholder="Message"
                    aria-required="true"
                    required
                  ></textarea>
                </div>
                <br />
                <input
                  className="btn btn-green px-12 mt-12 g-recaptcha"
                  name="email-button"
                  type="submit"
                  value="Submit"
                  onClick={verifyCallback}
                />
                <ReCAPTCHA
                  size="invisible"
                  ref={recaptchaRef}
                  sitekey="6Lc496IZAAAAAOfZ5hOMJEEbdMMQQdIVIfypZ7PC"
                  onChange={handleSubmit}
                />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
